import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Box, Button, Grid, MenuItem, useTheme} from '@mui/material';
import PageSection from '../common/PageSection';
import FormControlInput from '../common/FormControlInput';
import FormControlSelect from '../common/FormControlSelect';
import CircularProgressButton from '../common/CircularProgressButton';
import UserFolderPermissionsTable from './UserFolderPermissionsTable';
import HelpSystemContext from '../../context/HelpSystemContext';

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)};
`;

// noinspection FunctionNamingConventionJS
function FolderForm(props) {

    const theme = useTheme();
    // noinspection ConditionalExpressionJS
    return (
        <HelpSystemContext.Consumer>
            {open =>
                <form onSubmit={props.handleSubmit}>
                    <Grid container justifyContent='center'>

                        <Grid container item md={open ? 12 : 8}>
                            <PageSection title='Folder Details' titleVariant='body1' pb={0}/>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.name} width='100' label='Folder Name' name='name'
                                              placeholder='Enter a folder name' onChange={props.setName}
                                              errorMessage={props.errors.name} required
                                              helperText="This is the SFTP Gateway Folder that you're adding."/>

                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <UserFolderPermissionsTable
                                userFolderPermissions={props.folderPermissions}
                                updatePermission={props.updatePermission}
                                loadingFolderPermissions={props.loadingFolderPermissions}/>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <Box mt={3}>
                                <FormControlSelect name='cloudConnectionId' width='100' type='select' defaultValue={null}
                                                   value={props.cloudConnectionId?.toString()}
                                                   label='Cloud Connection' onChange={props.setCloudConnectionId}
                                                   helperText='This is the cloud connection that will be used by this folder.'
                                >
                                    <MenuItem value={'-1'}>Inherited</MenuItem>
                                    {props.cloudConnections.map((cloudConnection) =>
                                        <MenuItem key={cloudConnection.id}
                                                  value={cloudConnection.id}>{cloudConnection.name}</MenuItem>
                                    )}
                                </FormControlSelect>
                            </Box>
                        </Grid>
                        <Grid container item justifyContent='flex-end' md={open ? 12 : 8}>
                            <CircularProgressButton theme={theme} type='submit' size='small' label='Save' mt={2} mr={1}/>
                            <StyledButton theme={theme} size='small' onClick={props.handleCancel}>Cancel</StyledButton>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

const CloudConnection = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FolderForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    name: PropTypes.string,
    cloudConnectionId: PropTypes.any,
    setName: PropTypes.func,
    setCloudConnectionId: PropTypes.func,
    cloudConnections: PropTypes.arrayOf(CloudConnection),
    folderPermissions: PropTypes.array,
    deleteFolder: PropTypes.func,
    updatePermission: PropTypes.func,
    loadingFolderPermissions: PropTypes.bool,
    errors: PropTypes.object,
};

FolderForm.defaultProps = {
    cloudConnections: [],
    cloudConnectionId: '',
};

export default FolderForm;
