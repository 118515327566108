import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import { Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Check, CreateSharp as CreateSharpIcon, OpenInNew} from '@mui/icons-material';
import httpStatus from '../../util/http_status';
import {isEmpty, isEmptyObject} from '../../util/helpers';
import {convertErrorsToObject, handleErrors} from '../../util/errorHandler';
import CustomAlert from './CustomAlert';
import FormControlInput, {StyledLink} from './FormControlInput';
import SuccessMessageComponent from './SuccessMessageComponent';
import {authenticationService} from '../../container/auth/authenticationService';
import {setAuthenticated} from '../../action';
import CircularProgressButton from "./CircularProgressButton";

// noinspection MagicNumberJS
const useStyles = makeStyles((theme) => ({
  editRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
  },
  iconMarginLeft: {
    marginLeft: theme.spacing(0.5)
  },
  emptyText: {
    color: theme.palette.action.active,
  },
  iconColor: {
    color: theme.palette.primary.main,
  }
}));

// noinspection FunctionNamingConventionJS
function InlineEditFile({
  save, name, updateMessage, emptyText, placeholder,
  label, labelVariant, uncontrolled,
  multiline, inputProps, showCount, helperText, prefix, style, displayValue,
  errorMessage, required, helpLink, autoComplete,
  startAdornment,
  endAdornment,
  onChange,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(!isEmpty(errorMessage));

  const [errors, setErrors] = useState({});
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const [selectedFile, setSelectedFile] = useState({
    file: null,
    name: ''
  });

  useEffect(() => {
    if (!isEmpty(errorMessage)) {
      setEditing(true);
    }
  }, [errorMessage]);

  const handleFileChange = (event) => {
    let inputFile = event.target.files[0];
    setSelectedFile({
      file: inputFile,
      name: inputFile?.name
    });
    onChange(inputFile)
  };

  const submit = async function inlineEditingSubmit(event) {
    // Check if the field is require
    if (required && isEmpty(selectedFile.file)) {
      setErrors({[name]: 'This field is required.'})
    } else { // If the value did change, call the api.
      setLoading(true);
      try {
        await save(event);
        setEditing(false);
        setShowSavedMessage(true);
        setErrors({});
      } catch (error) {
        if (isEmpty(error.response)) {
          setErrors(error);
        } else if (error.response.status === httpStatus.badRequest) {
          let tempErrors = convertErrorsToObject(error.response);
          setErrors(tempErrors);
        } else if (error.response.status === httpStatus.conflict) {
          let error = {};
          error[name] = 'Duplicate entry.';
          setErrors(error);
        } else if (error.response.status === httpStatus.unAuthorized) {
          const tempErrors = handleErrors(error.response);
          toast.notify(({onClose}) =>
              <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
          authenticationService.clearTokenInfo();
          dispatch(setAuthenticated(false));
        }
      } finally {
        setLoading(false)
      }
    }
  };

  const handleKeyDown = function inlineEditingKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit(event);
    }
  };

  // Build display when in not editing state
  const display = function displayNonEditingValue() {
    let body = [];
    if (!isEmpty(prefix)) {
      body.push(<span key='1' style={{marginRight: '8px'}}>{prefix}</span>);
    }
    if (!isEmpty(displayValue)) {
      body.push(<span key='2'>{displayValue}</span>);
    } else {
      body.push(<span key='4' className={classes.emptyText}>{emptyText}</span>);
    }
    return body;
  };

  // noinspection FunctionWithMoreThanThreeNegationsJS
  return (<div style={{display: 'flex', flexDirection: 'column', marginTop: '16px', width: '100%', ...style}}>
    <div style={{display: 'flex', alignItems: 'center'}}>

      <Typography gutterBottom variant={labelVariant}>{label}</Typography>
      <SuccessMessageComponent mb={1} ml={isEmpty(label) ? 0 : 2} message={updateMessage} show={showSavedMessage}
                               setShow={setShowSavedMessage}/>
      {!isEmptyObject(helpLink) && editing &&
          <StyledLink
              href={helpLink?.href}
              target={helpLink?.target}
              className={'help-text-link'}
              rel='noopener'
          >
            {helpLink?.target === '_blank' &&
                <OpenInNew fontSize='small' style={{verticalAlign: 'sub', marginRight: '4px'}}/>}
            {helpLink?.text}
          </StyledLink>
      }
    </div>
    {editing &&
        <Grid container item style={{marginTop: '1rem'}}>
          <FormControlInput
              uncontrolled={uncontrolled}
              autoFocus={isEmpty(errorMessage)}
              onKeyDown={handleKeyDown}
              onChange={handleFileChange}
              errorMessage={isEmpty(errors[name]) ? errorMessage : errors[name]}
              margin='none'
              placeholder={placeholder}
              multiline={multiline}
              inputProps={inputProps}
              showCount={showCount}
              width={80}
              helperText={helperText}
              prefix={prefix}
              autoComplete={autoComplete}
              type='file'
              endAdornment={endAdornment}
              startAdornment={startAdornment}
          />
          <CircularProgressButton buttonTextTransform='none' mt={0} label={<Check/>}
                                  size='small'
                                  inProgress={loading}
                                  disabled={!selectedFile.file}
                                  onClick={submit}
          />
        </Grid>
    }
    {!editing &&
        <Typography variant='body2' onClick={() => setEditing(true)}>
          {display()}
          <IconButton aria-label='Edit' size='small'
                      onClick={() => setEditing(true)} className={classes.iconMarginLeft}>
            <CreateSharpIcon fontSize='small' className={classes.iconColor}/>
          </IconButton>
        </Typography>
    }
  </div>);
}

InlineEditFile.propTypes = {
  save: PropTypes.func.isRequired,
  name: PropTypes.string,
  updateMessage: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  emptyText: PropTypes.string,
  helperText: PropTypes.string,
  prefix: PropTypes.string,
  multiline: PropTypes.bool,
  inputProps: PropTypes.any,
  style: PropTypes.object,
  displayValue: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  uncontrolled: PropTypes.bool,
  endAdornment: PropTypes.object,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
};
InlineEditFile.defaultProps = {
  emptyText: 'None',
  labelVariant: 'caption',
  updateMessage: 'Saved',
  displayValue: null,
  errorMessage: null,
  required: false,
  autoComplete: 'on',
};
export default InlineEditFile;